
import Container from '@/components/common/Container.vue'
import Empty from '@/components/common/Empty.vue'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Container,
    Empty
  },
  setup () {
    const router = useRouter()
    const { t } = useI18n()
    const backHome = () => {
      router.push('/')
    }
    return {
      t,
      backHome
    }
  }
})
