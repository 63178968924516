
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    isMain: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    descClass: {
      type: String,
      default: ''
    },
    useOneLine: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: ''
    }
  },
  emits: ['btnClick'],

  setup () {
    const { t } = useI18n()
    return {
      t
    }
  }
})

