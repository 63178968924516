import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  class: "text-3xl mt-3 mb-4 font-bold text-everPurple2"
}
const _hoisted_3 = { key: 5 }
const _hoisted_4 = { class: "text-everPurple1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["text-base flex flex-col items-center justify-center", _ctx.isMain ? 'absolute w-full top-1/2 transform -translate-y-1/2' : '']),
    style: _normalizeStyle(_ctx.isMain ? '' : 'padding-top:70px;')
  }, [
    (_ctx.$slots.img)
      ? _renderSlot(_ctx.$slots, "img", { key: 0 })
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "w-20 h-20 block",
          src: require('../../images/fantasmas.svg'),
          alt: "fantasmas"
        }, null, 8, _hoisted_1)),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (!_ctx.useOneLine)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(_ctx.descClass ? _ctx.descClass : 'font-light mt-5 mb-2 text-everPurple3 text-center')
        }, _toDisplayString(_ctx.desc), 3))
      : _createCommentVNode("", true),
    (!_ctx.useOneLine)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "font-medium cursor-pointer text-everPurple0",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btnClick')))
        }, _toDisplayString(_ctx.btnText), 1))
      : _createCommentVNode("", true),
    (_ctx.useOneLine)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.desc), 1),
          _createElementVNode("span", {
            class: "text-everPurple0 ml-1 cursor-pointer",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('btnClick')))
          }, _toDisplayString(_ctx.btnText), 1)
        ]))
      : _createCommentVNode("", true)
  ], 6))
}