import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empty = _resolveComponent("empty")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, null, {
    default: _withCtx(() => [
      _createVNode(_component_empty, {
        title: _ctx.t('not_found'),
        desc: _ctx.t('not_found_desc'),
        "is-main": true,
        "use-one-line": true,
        "btn-text": _ctx.t('back_to_home'),
        onBtnClick: _ctx.backHome
      }, null, 8, ["title", "desc", "btn-text", "onBtnClick"])
    ]),
    _: 1
  }))
}